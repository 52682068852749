.hidden{
    display: none;
}
.nag-main{
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    background: rgba(43, 43, 43, 0.562);
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .nag-container{
        width: 70%;
        background-color: white;
        position: relative;
        .exit{
            position: absolute;
            color: white;
            top: 20px;
            right: 20px;
            width: 35px;
            height: 35px;
        }
        .red-alert{
            background-color: #ec4333;
            color: #ffffffad;
            padding: 20px 10px 10px 10px;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                gap: 20px;
                h1{
                    font-size: 50px;
                }
                .big-alert{
                    height: 45px;
                    width: 45px;
                }
            }
            p{
                padding-left: 30px;
                color: white;
            }
        }
        .message{
            padding: 10px 10px 20px 30px;
            h3{
                padding-bottom: 8px;
            }
            p{
                padding-bottom: 6px;
                padding-left: 6px;
                display: flex;
                gap: 5px;
                align-items: center;
                .ticker{
                    color: green;
                }
            }
        }
        .butmn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            button{
                border: none;
                padding: 5px 10px 5px 10px;
                height: 49px;
                margin-top: 50px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #c3c3c3;
                color: #000000;
                text-transform: uppercase;
                font-size: 15px;
                letter-spacing: 2px;
                transition: all 0.5s;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}
.act-main{
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    background: rgba(43, 43, 43, 0.562);
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .nag-container{
        width: 70%;
        background-color: white;
        position: relative;
        .exit{
            position: absolute;
            color: white;
            top: 10px;
            right: 10px;
            width: 25px;
            height: 25px;
        }
        .green-alert{
            background-color: #6af781;
            color: #ffffffad;
            padding: 20px 10px 10px 10px;
            .green-alert-con{
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 5px;
                flex-wrap: wrap;
                gap: 20px;
                .alert-option{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    h1{
                        font-size: 25px;
                    }
                    .big-alert{
                        height: 35px;
                        width: 35px;
                    }
                }
                .alert-active{
                    background-color: #34c73bb8;
                    padding: 5px 10px;
                    border-radius: 5px;
                    h1{
                        color: white;
                    }
                    .big-alert{
                        color: white;
                    }
                }
            }
            p{
                padding-left: 30px;
                color: white;
            }
        }
        .message{
            padding: 10px 10px 20px 30px;
            h3{
                padding-bottom: 8px;
            }
            p{
                padding-bottom: 6px;
                padding-left: 6px;
                display: flex;
                gap: 5px;
                align-items: center;
                .ticker{
                    color: green;
                }
            }
        }
        .butmn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            button{
                border: none;
                padding: 5px 10px 5px 10px;
                height: 49px;
                margin-top: 50px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #c3c3c3;
                color: #000000;
                text-transform: uppercase;
                font-size: 15px;
                letter-spacing: 2px;
                transition: all 0.5s;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}
@media (max-width: 950px) {
    .nag-main{
        .nag-container{
            width: 90%;
            background-color: white;
            position: relative;
            .exit{
                position: absolute;
                color: white;
                top: 5px;
                right: 5px;
                width: 25px;
                height: 25px;
            }
            .red-alert{
                padding: 15px 10px 10px 10px;
                div{
                    padding: 5px;
                    gap: 15px;
                    h1{
                        font-size: 30px;
                    }
                    .big-alert{
                        height: 30px;
                        width: 30px;
                    }
                }
                p{
                    padding-left: 20px;
                    color: white;
                }
            }
            .message{
                padding: 10px 10px 15px 20px;
                h3{
                    padding-bottom: 20px;
                }
                p{
                    font-size: 13px;
                    padding-bottom: 6px;
                    padding-left: 6px;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }
            }
            .butmn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                button{
                    border: none;
                    padding: 5px 10px 5px 10px;
                    height: 49px;
                    margin-top: 50px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #c3c3c3;
                    color: #000000;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: 2px;
                    transition: all 0.5s;
                    position: relative;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
        }
    }
    .nag-container{
        width: 95% !important;
    }
    
}