.user-data {
    margin: 18px 0px 18px 18px;
    background-color: white;
    width: 100%;

    .title-user {
        padding: 15px 20px;

        h4 {
            font-size: 18px;
        }
    }

    .user-info-data {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;

        .info-con {
            max-width: 400px;
            width: 100%;
            padding: 15px;

            p {
                font-size: 15px;
                font-weight: 600;
                padding-bottom: 5px;
            }

            input {
                padding: 10px;
                width: -webkit-fill-available;
                background-color: #e5e5e5;
                font-size: 15px;
                border-radius: 10px;
            }
        }
    }

    .referral-con {
        margin-top: 25px;

        .ref-ttl {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 5px;
            padding-left: 15px;
        }

        .ref-act {
            display: flex;
            padding-left: 15px;
            padding-bottom: 30px;
            align-items: center;
            gap: 40px;

            input {
                padding: 10px;
                width: 60%;
                background-color: #e5e5e5;
                font-size: 15px;
                border-radius: 5px;
            }

            p {
                height: 40px;
                width: 80px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #1976d2;
                cursor: pointer;
            }
        }
    }
}

.dashcon {
    padding: 18px;

    .dashhere {
        width: auto !important;
    }
}

.bunner-incite {
    margin: 15px 0;
    border-radius: 10px;
    background: linear-gradient(135deg, rgb(214 255 198), rgb(143 255 109));

    p {
        padding: 20px 20px;
        max-width: 800px;
    }

    .director-tabs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .tabs {
            padding-top: 10px;
            padding-bottom: 10px;

            p {
                padding: 12px;
                border-radius: 5px;
                background-color: #1976d2;
                width: fit-content;
                color: #ebebeb;
                font-size: 15px;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}

.wel-ttl {
    padding: 15px 0;
    position: relative;
    z-index: -1;

    h1 {
        position: relative;

        span {
            color: rgb(94, 94, 94);
            left: 3px;
        }
    }
}

.welc-ban {
    display: flex;
    background: rgba(0, 111, 255, .1);
    border-radius: 5px;
    margin-bottom: 10px;
    align-items: center;

    .img-con {
        width: 220px;
        height: 150px;
        padding: 15px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .text-con {
        .text-one {
            p {
                margin: 10px 0;
                font-family: Karla, sans-serif;
                font-weight: 600;
                font-size: 19px;

                span {
                    color: blue;
                }
            }
        }
    }
}

.task-tab {
    padding: 10px;
    background: linear-gradient(135deg, rgb(214 255 198), rgb(143 255 109));
    border-radius: 10px;
    .intro-task {
        display: flex;
        justify-content: space-between;
        padding: 20px 5px 30px 8px;
        align-items: center;

        .main-intro {
            font-size: 18px;
            font-weight: 600;
        }

        .toggle {
            border-radius: 5px;
            background: #1976d2;
            cursor: pointer;
            padding: 10px 10px;
            color: white;
        }
    }
}

.hidden {
    display: none !important;
}

.task-con {
    padding: 15px 0px;

    .task-item {
        display: flex;
        justify-content: space-between;
        background-color: white;
        border-radius: 5px;
        padding: 14px 8px;
        margin: 15px 0px;
        align-items: center;

        p {
            font-size: 14px;
            padding: 0px 5px;
        }
        .cash{
            font-weight: 600;
            color: green;
        }
    }
}

.cool {
    border-radius: 5px;
    border: none;
    background-color: #1976d2;
    color: white;
    font-size: 16px;
    padding: 8px 5px;
    cursor: pointer;
&:hover{
    background: #4a7fb4;
}}

.subs{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: white;
    margin: 18px 0;
    padding: 15px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .sub-two{
        margin-left: 5%;
        padding: 10px 10px;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        background: #1976d2;
    }
    .sub-one{
        padding: 18px 18px 18px 10px;
    }
}

.instructions{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: white;
    margin: 18px 0;
    padding: 15px 5px;
    border-radius: 5px;
    .inst-tt{
        display: flex;
        justify-content: space-between;
        p{
            margin-left: 5%;
            padding: 10px 10px;
            background-color: #36e536;
            border-radius: 5px;
            color: white;
            cursor: pointer;
        }
    }
    .inst-pp{
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
.intro-top{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: white;
    margin: 18px 0;
    padding: 15px 5px;
    border-radius: 5px;
    .intro-sub{
        display: flex;
        align-items: center;
        height: 50px;
        .date-tag{
            margin: 5px 15px;
            font-weight: 600;
        }
        .button-tag{
            margin-left: 5%;
            padding: 10px 10px;
            background-color: #36e536;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    .image-con{
        margin: 20px 18px;
        height: 400px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #edeef3;
        border-radius: 5px;
        img{
            height: 100%;
        }
    }
    .submit-tag{
        margin: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-left: 5%;
            padding: 10px 10px;
            background: #1976d2;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            font-weight: 600;
        }
    }
}
.what-sub-con{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #e5e5e581;
    display: flex;
    justify-content: center;
    align-items: center;
    .what-con{
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        background-color: white;
        width: 70%;
        padding: 25px 25px;
        border-radius: 5px;
        position: relative;
        .form-main{
            .form-item{
                position: relative;
                margin-bottom: 21px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 15px;
                input{
                    outline: none;
                    border: none;
                    border-bottom: 1px solid #e6e6e6;
                    display: block;
                    width: 100%;
                    height: 38px;
                    background: none;
                    padding: 3px 42px 0px;
                    color: #666;
                    font-size: 16px;
                    box-sizing: border-box;
                &:focus{
                    border-bottom: 1px solid #1976d2;
                }}
                span{
                    position: absolute;
                    left: 10px;
                    top: 60px;
                    transform: translateY(-50%);
                    font-size: 15px;
                    color: #333;
                    box-sizing: border-box;
                }
            }
            .submit-form{
                border-radius: 5px;
                background: #1976d2;
                cursor: pointer;
                padding: 10px 10px;
                color: white;
                border: none;
                margin-top: 20px;
                font-size: 18px;
            }
        }
    }
}
.hidden{
    display: none !important;
}
.leave{
    position: absolute;
    z-index: 10;
    right: 34px;
    height: 30px;
    width: 30px;
}
@media (max-width: 950px) {
    .bunner-incite{
        p{
            font-size: 12px;
        }
    }
    .tabs{
        padding: 0;
        p{
            font-size: 12px !important;
        }
    }
    .ref-act{
        gap: 20px !important
    }
}