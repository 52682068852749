.feedback-container {
    background-color: white;
    height: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;

    p {
        top: 5px;
        position: relative;
        font-size: 22px;
        font-weight: 600;
    }
}

.rating-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20%;
    display: flex;
    gap: 20px;
}

.star {
    cursor: pointer;
}

.stars {
    height: 38px;
    width: 38px;
    color: lightgray;
}

.active {

    color: gold;
}

.feedback-input {
    color: #3c3c3c;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: #e7e7e7;
    padding: 13px 28px 13px 28px;
    margin-bottom: 10px;
    margin: 0 10%;
    width: 80%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid rgba(0, 0, 0, 0);

    &:focus {
        background: #fff;
        box-shadow: 0;
        border: 3px solid #3498db;
        color: #3498db;
        outline: none;
        padding: 13px 13px 13px 24px;
    }
}

#comment {
    background-image: url(http://rexkirby.com/kirbyandson/images/comment.svg);
    background-size: 30px 30px;
    background-position: 11px 8px;
    background-repeat: no-repeat;
}

textarea {
    width: 80%;
    height: 150px;
    line-height: 150%;
    resize: vertical;
}

.comment-area {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 15px 0px;
    padding: 10px 8px;
    border-radius: 5px;

    p {
        font-weight: 600;
        font-size: 19px;
        padding: 10px 0px 20px 30px;
    }
}

.progress-bar-con {
    display: flex;
    margin: 18px 0;
    background: white;
    padding: 18px 10px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.progress-bar {
    width: 60%;
    min-width: 200px;
    /* Adjust as needed */
    height: 20px;
    background-color: #ddd;
    /* Light gray background */
    border-radius: 5px;
    /* Optional rounded corners */
    overflow: hidden;
    /* Hide overflow for smooth transition */
}

.progress-bar-fill {
    height: 100%;
    background-color: #4CAF50;
    /* Green fill color */
    width: 0%;
    /* Initial width is 0% */
    transition: width 300ms ease;
    /* Smooth transition */
}

.submit-button {
    margin: 0 40px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    /* Add some margin for spacing */
}

.submit-button:disabled {
    background-color: #ddd;
    /* Make the button look disabled */
    cursor: not-allowed;
}

.tasks-con {
    margin: 18px 0px;

    .title-task {
        padding: 5px 10px;
    }

    .item-con {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .item-single {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 18px 5px;
            background: white;
            border-radius: 5px;

            .single-p {
                font-size: 15px;
                width: 100px;

                span {
                    font-weight: 500;
                }
            }

            .pend {
                background-color: #e7e7e7;
                border-radius: 5px;
                padding: 7px 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .rejected {
                background-color: #ff7e7e;
                border-radius: 5px;
                padding: 7px 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .approved {
                background-color: #83ff83;
                border-radius: 5px;
                padding: 7px 4px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }
    }
}

.ttl-item-single {
    background-color: #c7c7c7;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 18px 5px;
    border-radius: 5px;

    .single-p {
        width: 100px;
        font-size: 15px;

        span {
            font-weight: 600;
        }
    }

}