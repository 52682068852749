.referral-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 17px;
    margin-top: 15px;
  }
  
  .table-header, .table-row {
    display: flex;
    width: 100%; /* Make rows fill the container */
  }
  
  .header-cell, .row-cell {
    flex: 1; /* Distribute space equally between cells */
    padding: 8px;
    border: 1px solid #ddd;
  }
  