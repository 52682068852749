@import 'variables';

* {
    padding: 0;
    margin: 0;
}

.hidesub{
    display: none !important;
}

.con-log{
    position: relative;
    .con-log-con{
        position: absolute;
        top: 60px;
        background-color: white;
        right: 0px;
        display: flex;
        flex-direction: column;
        p{
            padding: 6px 17px;
            display: flex;
            gap: 10px;
        }
    }
}

.open {
    .con-main::after{
        content: ' ';
        position: absolute;
        width: 10px;
        height: 100%;
        right: -10px;
        background-color: $secondary-color;
    }
    .con-main{
        .move-con{
            .menu-title{
                color: #f6f6f6 !important;
            }
        }
    }
    .icon-r {
        transform: rotate(90deg);
    }

    .menu-content {
        display: block !important;
    }
}

body {
    margin: 0;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #edeef3;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.constant {
    .nan-main {
        width: 100%;
        height: 75px;
        background-color: white;
        position: fixed;
        display: flex;
        z-index: 1;

        .mob-full {
            background-color: #2c343b;
            position: relative;
            transition: ease-in 300ms;
            width: 100%;

            .burger,
            .three-dots {
                display: none;
            }

            .logoHere {
                display: flex;
                align-items: center;

                h3 {
                    color: #fff;
                    padding-left: 6px;
                    font-weight: 500;
                    vertical-align: middle;
                    margin-bottom: .5rem;
                    font-family: Quicksand, Georgia, "Times New Roman", Times, serif;
                }
            }
        }

        .mob-hid {
            transition: ease-in 300ms;
            top: 0;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .right-group {
                padding-left: 30px;
                cursor: pointer;
            }

            .left-group {
                display: flex;
                align-items: center;
                padding-right: 30px;
                gap: 20px;
                cursor: pointer;

                h3 {
                    font-family: Quicksand, Georgia, "Times New Roman", Times, serif;
                    color: #6b6f82;
                    line-height: 23px;
                    font-weight: 700;
                    position: relative;
                    left: 10px;
                    bottom: 4px;
                }
            }
        }
    }

    .lay-layout {
        padding-top: 75px;
        display: flex;
        width: 100%;
        height: 100%;

        .nav-main {
            height: 90vh;
            transition: ease-in 300ms;

            .pan-content {
                overflow: scroll;
                position: fixed;
                height: 90vh;
                z-index: 100;
                top: 75px;
                background-color: $primary-color;
                transition: ease-in 300ms;

                .pan-item-main {
                    .nav-item {
                        .con-main {
                            cursor: pointer;
                            left: 0;
                            transition: ease-in 300ms;
                            color: #dcdcdc;
                            padding: 18px 0px;
                            width: 250px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            .move-con{
                                display: flex;
                                align-items: center;    
                                position: relative;
                                left: 0;
                                transition: ease-in 300ms;
                                .icon-p {
                                    padding-left: 40px;
                                    padding-right: 20px;
                                    height: 20px;
                                    width: 20px;
                                }
                            } & :hover{
                                transition: ease-in 300ms;
                                left: 8px;
                                color: #f6f6f6;
                            }

                            .icon-r {
                                transition: ease-in 300ms;
                                position: absolute;
                                right: 25px;
                                top: 34%;
                                height: 20px;
                                width: 20px;
                            }
                        }

                        .menu-content {
                            cursor: pointer;
                            display: none;
                            transition: ease-in 300ms;

                            .is-shown {
                                transition: ease-in 300ms;
                                color: #dcdcdc;
                                padding: 18px 0px;
                                display: flex;
                                align-items: center;
                                position: relative;
                                left: 0px;
                                padding-left: 80px ;
                            } & :hover{
                                left: 5px;
                                color: #f6f6f6;
                            }
                        }
                    }
                }
            }
        }

        .content-main {
            width: 100%;
        }
    }

    .fo-main {
        width: 100%;
        height: 45px;
        background-color: white;
    }

}

.main-base {
    .nan-main {

        .mob-full {
            width: 260px;
            align-items: center;
            display: flex;

            .dot {
                position: absolute;
                top: 30%;
                right: 30px;
                height: 30px;
                width: 30px;
            }

            .logoHere {
                img {
                    transition: ease-in 300ms;
                    padding-left: 45px;
                }

                h3 {
                    position: relative;
                    top: 5px;
                    left: 6px;
                }
            }

        }

        .mob-hid {}
    }

    .lay-layout {

        .nav-main {
            width: 260px;
            flex-shrink: 0;
            .pan-content {
                width: 260px;
            }
        }

        .content-main {}
    }

    .fo-main {}
}

.small-base {
    .nan-main {

        .mob-full {
            width: 60px;
            justify-content: center;
            align-items: center;
            display: flex;

            .dot {
                display: none;
            }

            .logoHere {
                justify-content: center;

                h3 {
                    display: none;
                }
            }
        }

        .mob-hid {
            flex-grow: 1;
        }
    }

    .lay-layout {

        .nav-main {
            width: 60px;
            flex-shrink: 0;
            .pan-content {
                width: 60px;
                .pan-item-main{
                    .nav-item{
                        .menu-content{
                            display: none !important;
                        }
                        .icon-r{
                            display: none;
                        }
                        .con-main{
                            width: 60px;
                            .move-con{
                                width: 100%;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                .icon-p{
                                    padding: 0;
                                    height: 30px;
                                    width: 30px;
                                }
                                .menu-title{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .content-main {
            width: auto;
        }
    }

    .fo-main {}
}

@media (max-width: 950px) {
    .user-info-data{
        gap: 0px !important;
    }
    .mobile-notactive {
        .nan-main {
            position: fixed;

            .mob-full {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 75px;
                z-index: 1;

                .burger {
                    display: block;
                    padding-left: 15px;
                }

                .dot {
                    display: none;
                }

                .three-dots {
                    padding-right: 25px;
                    display: block;
                    right: 20px;
                    height: 20px;
                    width: 20px;
                }

                .logoHere {
                    h3 {
                        display: block;
                        position: relative;
                        top: 5px;
                        left: 6px;
                    }

                    img {
                        transition: ease-in 300ms;
                        padding: 0;
                    }
                }
            }

            .mob-hid {
                position: fixed;
                width: 100%;
                height: 75px;
            }
        }

        .lay-layout {
            padding-top: 75px;
            display: flex;
            width: 100%;
            height: 100%;

            .nav-main {
                width: 0px;

                .pan-content {
                    width: 260px;
                    top: 75px;
                    background-color: $primary-color;
                    left: -260px;
                }
            }

            .content-main {
                width: 100%;
            }
        }

        .fo-main {}
    }

    .mobile-active {
        .nan-main {
            position: fixed;

            .mob-full {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 75px;
                z-index: 1;

                .burger {
                    display: block;
                    padding-left: 15px;
                }

                .dot {
                    display: none;
                }

                .three-dots {
                    padding-right: 25px;
                    display: block;
                    right: 20px;
                    height: 20px;
                    width: 20px;
                }

                .logoHere {
                    h3 {
                        display: block;
                        padding-top: 5px;
                    }

                    img {
                        transition: ease-in 300ms;
                        padding: 0;
                    }
                }

            }

            .mob-hid {
                position: fixed;
                width: 100%;
                height: 75px;
            }
        }

        .lay-layout {
            padding-top: 75px;
            display: flex;
            width: 100%;
            height: 100%;

            .nav-main {
                width: 0px;

                .pan-content {
                    .sheet{
                        position: fixed;
                        left: 0;
                        height: 100vh;
                        top: 75px;
                        width: 100%;
                        background-color: #44444479;
                    }
                    width: 260px;
                    top: 75px;
                    background-color: $primary-color;
                    left: 0px;
                }
            }

            .content-main {
                width: 100%;
            }
        }

        .fo-main {}
    }

    .mobile-active-bar {
        .nan-main {
            .mob-hid {
                top: 75px;
                background-color: white;
            }
        }
    }
    .dialogue-ttl {
        h4{
            font-size: 12px !important;
            padding-left: 12px !important;
        }
    }
}

.dialogue-con{
    margin: 18px 0px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    .dialogue-ttl{
        margin: 18px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4{
            font-size: 25px;
            padding-left: 60px;
        }
        .allow{
            background-color: rgb(114, 245, 125);
        }
        .reject{
            background-color: #afafaf;
        }
        .stat{
            padding: 10px 15px;
            border-radius: 5px;
        }
    }
    .icons-con{
        margin: 12px 10px;
        display: flex;
        gap: 17px;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
        .single-icon{
            flex-grow: 1;
            min-width: 170px;
            border-radius: 5px;
            padding: 14px;
            background: linear-gradient(135deg, rgb(35 19 241), rgb(134 127 191));;
            div{
                display: flex;
                padding: 8px;
                justify-content: space-between;
                align-items: center;
                color: #ebebeb;
            }
            p{
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}
.form-main-wallet{
    font-family: Quicksand, Georgia, "Times New Roman", Times, serif;
    box-sizing: border-box;
    padding: 35px;
    .form-item-wallet{
        position: relative;
        margin-bottom: 21px;
        box-sizing: border-box;
        span{
            position: absolute;
            left: 10px;
            top: 40px;
            transform: translateY(-50%);
            font-size: 15px;
            color: #333;
            box-sizing: border-box;
        }
        input{
            outline: none;
            border: none;
            border-bottom: 1px solid #e6e6e6;
            display: block;
            width: 100%;
            height: 38px;
            background: none;
            padding: 3px 42px 0px;
            color: #666;
            font-size: 16px;
            box-sizing: border-box;
            &:focus {
                background: #fff;
                box-shadow: 0;
                border: 1px solid #3498db;
                color: #3498db;
                outline: none;
            }
    
        }
    }
    .submit-form-wallet{
        border: none;
        width: 100%;
        height: 49px;
        margin-top: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: #1b8dff;
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 2px;
        transition: all 0.5s;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
    }
}
.item-while{
    padding: 18px;
}