.invalid{
	border-bottom: 1px solid red !important;
}
.done-con{
	z-index: 100;
	position: fixed;
	height: 100vh;
	width: 100%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #9999997e;
	.comp-mess{
		width: 60%;
		background-color: white;
		padding: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		h3{
			color: rgb(48, 233, 48);
			padding: 10px;
		}
		p{
			font-size: 20px;
			padding: 10px;
		}
		.big-tick{
			height: 90px;
			width: 90px;
			color: rgb(48, 233, 48);
		}
	}
}
.hidden{
	display: none !important;
}
.logoHere-auth{
    display: flex;
    align-items: center;
    gap: 18px;
    padding-left: 10px;
    h3{
        position: relative;
        top: 10px;
        font-size: 30px;
    }
}
.ttl-intro{
    padding: 25px 0;
    h4{
        padding-bottom: 10px;
    }
}
.wrapper {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #accffe ;
    box-sizing: border-box;
    font-family: Quicksand, Georgia, "Times New Roman", Times, serif;
}
.inner {
	position: relative;
	width: 435px;
    box-sizing: border-box;
}
.image-1 {
	position: absolute;
	bottom: -12px;
	left: -191px;
	z-index: 99;
    box-sizing: border-box;
}
.image-2 {
	position: absolute;
	bottom: 0;
	right: -129px;
    box-sizing: border-box;
}
.form-main {
	width: 100%;
	position: relative;
	z-index: 9;
	padding: 77px 61px 66px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}
.form-holder {
	position: relative;
	margin-bottom: 21px;
    box-sizing: border-box;
	span {
		position: absolute;
		left: 9px ;
		top: 22px;
		transform: translateY(-50%);
		font-size: 15px;
		color: #333;
        box-sizing: border-box;
		&.lnr-lock {
			left: 2px;
            box-sizing: border-box;
		}
	}
}
.form-control {
	outline: none;
	border: none;
	border-bottom: 1px solid #e6e6e6;
	display: block;
	width: 100%;
	height: 38px;
	background: none;
	padding: 3px 42px 0px;
	color: #666;
	font-size: 16px;
    box-sizing: border-box;
	&::-webkit-input-placeholder { 
		font-size: 14px;
		color: #999;
		transform: translateY(1px);
        box-sizing: border-box;
	}
	&::-moz-placeholder { 
		font-size: 14px;
		color: #999;
		transform: translateY(1px);
        box-sizing: border-box;
	}
	&:-ms-input-placeholder { 
		font-size: 14px;
		color: #999;
		transform: translateY(1px);
        box-sizing: border-box;
	}
	&:-moz-placeholder { 
		font-size: 14px;
		color: #999;
		transform: translateY(1px);
        box-sizing: border-box;
	}
	&:focus {
		border-bottom: 1px solid #accffe;
        box-sizing: border-box;
	}
}
.log-main-btn {
	border: none;
	width: 100%;
	height: 49px;
	margin-top: 50px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background: #1b8dff;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	letter-spacing: 2px;
	transition: all 0.5s;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    span {
    	position: relative;
    	z-index: 2;
        box-sizing: border-box;
    }
    &:before, &:after {
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 1;
	    background-color: rgba(52,152,253,0.25);
	    -webkit-transition: all 0.3s;
	    -moz-transition: all 0.3s;
	    -o-transition: all 0.3s;
	    transition: all 0.3s;
	    -webkit-transform: translate(-100%,0);
	    transform: translate(-100%,0);
	    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        box-sizing: border-box;
    }
    &:after {
    	-webkit-transition-delay: 0.2s;
    	transition-delay: 0.2s;
        box-sizing: border-box;
    }
    &:hover {
        box-sizing: border-box;
    	&:before, &:after {
    		-webkit-transform: translate(0,0);
    		transform: translate(0,0);
            box-sizing: border-box;
    	}
    }
}
@media (max-width: 991px) {
	.inner {
		width: 400px;
		left: 4%;
        box-sizing: border-box;
	}
}
@media (max-width: 767px) {
	.inner {
		left: 0;
        box-sizing: border-box;
	}
	.image-1, .image-2 {
		display: none;
        box-sizing: border-box;
	}
	.form-main {
		padding: 35px;
		box-shadow: none;
	    -webkit-box-shadow: none;
	    -moz-box-shadow: none;
	    -ms-box-shadow: none;
	    -o-box-shadow: none;
        box-sizing: border-box;
	}
	.wrapper {
		background: none;
        box-sizing: border-box;
	}
}

.loader-class{
	z-index: 100;
	position: fixed;
	height: 100vh;
	width: 100%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4b4b4b7e;
	.loader{
		background: #000;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		animation-name: pulse;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}
}
@keyframes pulse {
	from {transform: scale(0.2);}
	to {transform: scale(01);}
  }